@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.Profile {
  container-type: inline-size;
  position: relative;
  overflow: hidden;
  // background-color: $--grey-100;
  background-color: $--background-navy;
  // background-image: url("../../assets/img/yellow_purple_background.jpeg");
  // background-image: url("../../assets/img/daccord.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 576px;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;

  > * {
    position: relative;
    z-index: 1;
  }
}

.SplineBackground {
  position: absolute;
  // width: 100%;
  inset: 0;
  width: 100%;
  overflow: hidden;
  // width: fit-content;

  canvas {
    position: absolute;
    width: 900px !important;
    height: 1800px !important;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
  }
}

.shareBtn {
  &:global(.MuiButtonBase-root) {
    position: absolute;
    z-index: 99;
    top: 40px;
    right: 30px;
    @include font-poppins();
    color: $--background-navy;
    // color: $--text-dark;

    background: linear-gradient(
      220deg,
      rgba($--color-white, 0.2) 0%,
      rgba($--color-white, 0.3) 100%
    );
    // background-color: $--color-white;

    box-shadow: 0 4px 8px 0 rgba($--color-black, 0.2);

    backdrop-filter: blur(3px);

    border-radius: 30px;
    // padding: pxrem(5 10);
    padding: pxrem(10);
    :global(.ico) {
      font-size: pxrem(16);
      margin-right: pxem(8, 16);
    }

    @container (max-width: 369px) {
      top: 30px;
      right: 15px;
    }
  }
}

.msgBtn {
  &:global(.MuiButtonBase-root) {
    position: absolute;
    z-index: 99;
    bottom: 40px;
    right: 30px;
    @include font-poppins();
    color: $--background-navy;
    // color: $--text-dark;

    background: linear-gradient(
      220deg,
      rgba($--color-white, 0.2) 0%,
      rgba($--color-white, 0.3) 100%
    );
    // background-color: $--color-white;

    box-shadow: 0 4px 8px 0 rgba($--color-black, 0.2);

    backdrop-filter: blur(3px);

    border-radius: 30px;
    // padding: pxrem(5 10);
    padding: pxrem(10);
    :global(.ico) {
      font-size: pxrem(16);
      margin-right: pxem(8, 16);
    }

    @container (max-width: 369px) {
      bottom: 30px;
      right: 15px;
    }
  }
}

.propic {
  position: relative;
  backdrop-filter: blur(4px);
  border-radius: 0 0 60px 60px;
  overflow: hidden;
  box-shadow: 10px 10px 8px 0 rgba($--background-black, 0.25);

  .companyIcon {
    position: absolute;
    width: 100px;
    z-index: 1;
    top: 20px;
    left: 20px;
    opacity: 0.7;
  }

  .basicInfo {
    // position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    // bottom: -1px;
    color: $--color-white;
    padding-top: 60px;
    font-size: 20px;
    background: linear-gradient(
      to bottom,
      rgba($--color-white, 0) 0%,
      rgba($--color-white, 0.15) 100%
    );

    .name {
      font-size: 36px;
      font-weight: 700;
    }
  }

  .infos {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  }
}

.defaultIcon {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: $--color-primary-dark;
  background-color: $--background-navy;

  :global(.ico) {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 120px;
    // color: $--grey-900;
    color: $--color-white;
    z-index: 1;

    text-shadow: 3px -3px rgba($--color-white, 0.3);
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      to top,
      rgba($--color-white, 0) 0%,
      rgba($--color-white, 0.15) 100%
    );
    pointer-events: none;
  }
}

.contactInfo {
  display: flex;
  justify-content: center;
  padding: 32px 16px;
  gap: 15px;
  background: linear-gradient(
    to bottom,
    rgba($--color-white, 0.15) 0%,
    rgba($--color-white, 0.2) 100%
  );
  // background-color: $--background-black;
  // border-radius: 0 0 60px 60px;

  @container (max-width: 369px) {
    gap: 8px;
    border-radius: 0 0 35px 35px;
  }

  .contactIcon {
    width: 1.8em;
    height: 1.8em;
    border-radius: 50%;
    color: $--color-white;
    transition: background-color $--hover;

    &:hover,
    &:focus {
      background-color: rgba($--color-white, 0.15);
    }

    font-size: 36px;

    @container (max-width: 525px) {
      font-size: 28px;
    }

    @container (max-width: 429px) {
      font-size: 24px;
    }
  }
}

.profileInfo {
  position: relative;
  .placeholder {
    // mask-image: linear-gradient(to top, rgba(black, 0.45) 25%, black 75%);
    mask-image: linear-gradient(
      to top,
      rgba($--background-black, 0) 0%,
      rgba($--background-black, 0.2) 25%,
      $--background-black 75%
    );
  }
}

@mixin infoBG {
  background: linear-gradient(
    to bottom,
    rgba($--color-white, 0.3),
    rgba($--color-white, 0.05)
  ); //rgba($--color-white, 0.15);
  backdrop-filter: blur(4px);
  box-shadow: 10px 10px 8px 0px rgba($--background-black, 0.2);
  // border: 3px solid rgba($--color-white, 0.25);
  // border-image-slice: 1;
  // border-width: 5px;
  // border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
}

.customInfo {
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;
  margin: 20px;
  // background-color: $--color-white;
  @include infoBG();
  color: $--grey-100;
  border-radius: 20px;

  &.narrow {
    gap: 10px;
  }

  .head {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    @container (max-width: 369px) {
      font-size: 20px;
      line-height: 1;
    }
  }
  .subhead {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    @container (max-width: 369px) {
      font-size: 18px;
      line-height: 1;
    }
  }
  .desc {
    font-size: 18px;
    text-align: center;
    @container (max-width: 369px) {
      font-size: 16px;
    }
  }
  .date {
    @include font-poppins();
    font-size: 16px;
    text-align: center;
    @container (max-width: 369px) {
      font-size: 14px;
    }
    span {
      display: inline;
      margin-inline: 12px;
    }
  }
}

.socialInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 24px;
  margin: 20px;
  color: $--grey-100;
  // background-color: $--color-white;
  @include infoBG();
  border-radius: 20px;

  .left {
    display: flex;
    align-items: center;
    gap: 20px;

    .icon {
      width: 40px;
      flex-shrink: 0;

      &.default {
        border-radius: 50%;
        border: 1px solid $--grey-200;
      }

      .linkIcon {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 20px;
        margin: auto;
      }
    }
    .media {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .mediaName {
        font-size: 18px;
        font-weight: 400;
      }
      .mediaLabel {
        // color: $--grey-100;
      }
    }
  }
  .right {
    color: $--grey-100;
  }
}

.ProfileShare {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  canvas {
    width: 100%;
  }
  .modalName {
    font-size: 24px;
    font-weight: 400;
    margin: 18px 0;
  }
  .modalContainer {
    container-type: inline-size;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    width: 100%;

    .fullbreed {
      display: flex;
      justify-content: center;
      grid-column: span 2;
    }
  }
  .modalGlass {
    display: flex;
    justify-content: center;
    backdrop-filter: blur(6px);
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0 10px 8px 0 rgba($--background-black, 0.2);
  }
  .modalAction {
    display: flex;
    flex-direction: column;
    @include font-poppins();

    :global(.ico) {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
}

.editMode {
  .propic,
  .shareBtn,
  .customInfo,
  .socialInfo {
    backdrop-filter: none;
  }
}
