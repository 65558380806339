@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.shareBtn {
  &:global(.MuiButtonBase-root) {
    @include font-poppins();
    color: $--text-dark;
    background-color: $--color-white;
    border-radius: 30px;
    padding: pxrem(5 10);
    :global(.ico) {
      font-size: pxrem(16);
      margin-right: pxem(8, 16);
    }
  }
}

.Dashboard {
  flex: 1;
  background-color: $--grey-100;
  padding-bottom: pxrem(60);
  min-height: 100vh;
}

.Cards {
  padding-top: pxem(24);
  padding-bottom: pxem(24);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: pxem(24);
    .search {
      width: 100%;
    }
    .add {
      flex-shrink: 0;
      padding: 0;
      :global(.MuiSvgIcon-root) {
        font-size: pxem(24);
        color: $--color-black;
      }
    }
    .tabs {
      margin: 0;
    }
    .previous {
      @include font-poppins();
      color: $--grey-900;
      display: flex;
      gap: pxem(14);
      font-size: pxem(20);

      :global(.MuiSvgIcon-root) {
        font-size: 24px;
        transition: transform $--hover;
      }

      &:hover {
        :global(.MuiSvgIcon-root) {
          transform: translateX(pxrem(-4));
        }
      }
    }

    &.wrap {
      .search {
        width: 50%;
      }
      @media screen and (max-width: 991px) {
        justify-content: center;
        flex-wrap: wrap;

        .search {
          width: 100%;
        }
      }
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(pxrem(200), 1fr));
    gap: pxem(55);

    @media screen and (max-width: 575px) {
      grid-template-columns: 0.8fr;
      justify-content: center;
      gap: pxrem(40);
    }

    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      min-height: pxrem(180);
      height: 100%;
      background-color: $--color-white;
      border: 1px dashed $--grey-400;
      border-radius: 16px;
      transition: $--hover;
      transition-property: color, border-color;

      .add {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: pxem(14);

        :global(.MuiSvgIcon-root) {
          font-size: pxem(50);
          margin-bottom: pxem(12, 50);
        }
      }

      &:hover {
        transform: none;
        color: darken($--color-primary-dark, 25%);
        border-color: darken($--color-primary-dark, 25%);
      }

      @media screen and (max-width: 575px) {
        display: none;
      }
    }

    .remarks {
      display: flex;
      align-items: center;
      width: 75%;
      height: 100%;
      @include font-poppins();
      color: $--color-black;
      font-size: pxem(14);
      font-weight: 300;

      @media screen and (min-width: 576px) {
        grid-column: span 2;
      }
    }
  }
}

.CardProfiles {
  .header {
    display: flex;
    gap: percentage(50 / 1440);
    margin-top: pxem(28);
    margin-bottom: pxem(28);

    .previous {
      @include font-poppins();
      color: $--grey-900;
      display: flex;
      gap: pxem(14);
      font-size: pxem(20);

      :global(.MuiSvgIcon-root) {
        font-size: 24px;
        transition: transform $--hover;
      }

      &:hover {
        :global(.MuiSvgIcon-root) {
          transform: translateX(pxrem(-4));
        }
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    gap: clamp(pxrem(26), percentage(50 / 1440), pxrem(50));
    padding: pxrem(26);
    overflow-x: scroll;

    @media screen and (max-width: 991px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    }

    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }

    .profile {
      max-width: pxrem(360);
      width: 100%;
      overflow: hidden;
      border-radius: pxem(10);
      box-shadow: 0 4px 4px rgba($--color-black, 0.25);

      @media screen and (max-width: 991px) {
        max-width: none;
      }
    }
    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      min-width: pxrem(200);
      min-height: pxrem(250);
      height: 100%;
      background-color: $--color-white;
      border: 1px dashed $--grey-400;
      border-radius: 16px;
      transition: $--hover;
      transition-property: color, border-color;

      .add {
        display: flex;
        flex-direction: column;
        align-items: center;

        :global(.MuiSvgIcon-root) {
          font-size: pxem(50);
          margin-bottom: pxem(12, 50);
        }
      }

      &:hover {
        transform: none;
        color: darken($--color-primary-dark, 25%);
        border-color: darken($--color-primary-dark, 25%);
      }

      @media screen and (max-width: 575px) {
        // display: none;
      }
    }

    .qrcode {
      @include font-poppins();
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: pxem(32);

      .title {
        font-size: pxem(24);
      }
      .qr {
        max-width: pxrem(224);
      }
      .copy {
        @include font-poppins();
        color: $--grey-900;
        font-size: pxem(18);
        font-weight: 400;
        padding: pxem(12, 18);
        margin: pxem(16 0, 18);
        display: flex;
        gap: pxem(10);
        border: 1px solid $--grey-900;
        border-radius: 0;

        :global(.ico) {
          font-size: pxem(24, 18);
        }
      }
    }
  }
}

.CardEdit {
  .header {
    display: flex;
    justify-content: space-between;
    gap: pxrem(20);
    padding-top: pxem(24);
    padding-bottom: pxem(24);

    @media screen and (max-width: 575px) {
      flex-direction: column;
      gap: 10px;
    }

    .left {
      min-width: pxrem(420);
      flex: 0 0 calc(50% - 10px);

      @media screen and (max-width: 767px) {
        min-width: auto;
        flex: auto;
      }
    }
    .right {
      display: flex;
      align-items: center;
      gap: pxrem(10);
      @include font-poppins();

      @media screen and (max-width: 575px) {
        justify-content: flex-end;
      }

      .cancel {
        padding: pxem(8 12);
      }
      .save {
        background-color: $--color-primary-dark;
        padding: pxem(8 30);
        border-radius: pxrem(50);
      }
    }

    .previous {
      @include font-poppins();
      display: flex;
      gap: pxem(14);
      font-size: pxem(20);

      :global(.MuiSvgIcon-root) {
        color: $--grey-900;
        font-size: 24px;
        transition: transform $--hover;
        &:hover {
          transform: translateX(pxrem(-4));
        }
      }
    }
    .cardname {
      :global {
        .MuiFormLabel-root {
          @include font-poppins();
          color: $--grey-500;
          &.Mui-error {
            color: $--color-danger;
          }
        }
        .MuiOutlinedInput-notchedOutline {
          border-radius: 0;
          border-color: $--grey-500;
          transition: border-color $--hover;
        }
        .MuiInputBase-root {
          @include font-poppins();
          background-color: $--color-white;
          &:hover,
          &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border-color: $--color-primary-dark;
            }
          }
          &.Mui-error {
            background-color: lighten($--color-danger, 52%);
            .MuiOutlinedInput-notchedOutline {
              border-color: $--color-danger;
            }
          }
        }
      }
    }
  }
  .content {
    @include font-poppins();
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: clamp(60px, percentage(60 / 1440), 90px);
    padding: pxrem(0 5 20);

    &.editing {
      grid-template-columns: 6fr 5fr;
    }

    @media screen and (max-width: 1319px) {
      &.editing {
        grid-template-columns: 7fr 5fr;
        gap: 35px;
      }
    }

    @media screen and (max-width: 991px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));

      &.editing {
        grid-template-columns: 1fr;
        gap: 30px;
      }
    }

    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }

    .contentRight {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media screen and (max-width: 1319px) {
        max-width: 500px;
        min-width: 340px;
      }

      @media screen and (max-width: 575px) {
        min-width: auto;
      }
    }
  }
}

.addFieldBtn {
  &:global(.MuiButtonBase-root) {
    @include font-poppins();
    font-size: 14px;
    margin-top: pxem(10, 14);
    padding: pxem(10 40, 14);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $--color-primary-dark;
    border-radius: 10px;
    transition: background-color $--hover;

    &:hover {
      background-color: rgba($--color-primary-dark, 0.15);
    }

    :global(.MuiTouchRipple-child) {
      background-color: $--color-primary-dark;
    }
  }
}

.addFieldMenu {
  &:global(.MuiMenu-paper) {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  :global(.MuiMenuItem-root) {
    @include font-poppins();
    transition: $--hover;
    transition-property: background-color, color;

    &:hover {
      color: $--color-white;
      background-color: $--color-info;
    }
  }
}

.ComponentField {
  & + .ComponentField {
    margin-top: 10px;
  }
  :global(.MuiAccordionDetails-root) & {
    &:first-child {
      margin-top: 15px;
    }
  }
}
.ComponentTextarea {
  width: 100%;
  :global {
    .MuiOutlinedInput-notchedOutline {
      display: none;
    }
    .MuiInputBase-multiline {
      @include font-poppins();
      align-items: flex-start;
      background-color: $--background-grey;
      border-radius: 20px;
      font-size: 16px;
      font-weight: 400;
      min-height: 150px;
      transition: background-color $--hover;
      textarea::placeholder {
        color: $--grey-500;
        opacity: 1;
      }
      &.Mui-error {
        textarea::placeholder {
          color: $--color-danger;
        }
        background-color: lighten($--color-danger, 52%);
      }
    }
  }
}
.ComponentInput {
  border-radius: 20px;
  overflow: hidden;

  :global {
    .MuiOutlinedInput-notchedOutline {
      display: none;
    }
    .MuiFormLabel-root {
      @include font-poppins();
      color: $--grey-500;
      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(14px, 5px) scale(0.75);
      }
      &.Mui-error {
        color: $--color-danger;
      }
    }
    .MuiInputBase-root {
      background-color: $--background-grey;
      border-radius: 0;
      transition: background-color $--hover;
      &.Mui-error {
        background-color: lighten($--color-danger, 52%);
      }
    }
    .MuiInputBase-input {
      @include font-poppins();
      padding-top: 23px;
      padding-bottom: 10px;
    }
  }

  &.centered {
    :global(.MuiInputBase-input) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}
.ComponentAccordion {
  &:global(.MuiAccordion-root) {
    border: 0;
    box-shadow: none;
    background: none;
  }
  :global(.MuiAccordionSummary-root) {
    display: flex;
    align-items: center;
    background-color: $--color-primary;
    border-radius: pxem(20);
    height: pxem(56);
    &:global(.Mui-expanded) {
      height: pxem(56);
      min-height: pxem(56);
    }
    @media screen and (max-width: 575px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  :global(.MuiAccordionSummary-content) {
    cursor: auto;
    align-items: center;
    gap: 10px;
    padding-right: 10px;
    @media screen and (max-width: 575px) {
      gap: 6px;
      padding-right: 6px;
    }
  }
  :global(.MuiAccordionDetails-root) {
    padding: 0;
    padding-top: 10px;
  }
  .accordionRow {
    row-gap: pxem(20);
    column-gap: 8px;
    margin: 10px;
    &.fullWidth {
      margin-left: 0;
      margin-right: 0;
    }
    .text {
      color: $--grey-500;
    }
    @media screen and (max-width: 575px) {
      flex-wrap: wrap;

      .text {
        display: none;
      }
    }
  }
  .accordionLabel {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-left: 10px;
  }
  .accordionBtn {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: pxem(20);
    width: pxem(36, 20);
    height: pxem(36, 20);
    color: $--grey-500;
    background-color: $--color-white;
    border-radius: 50%;
    box-shadow: 0 1px 4px 0 rgba($--color-black, 0.25);
    transition: background-color $--hover;

    &:hover {
      background-color: darken($--color-white, 3%);
    }

    &.yellow {
      box-shadow: 0 0 4px 2px $--color-primary-dark;
    }

    &.disabled {
      cursor: not-allowed;
      &:hover {
        background-color: $--color-white;
      }
    }
  }
  :global(.dropzone) {
    width: fit-content;
    outline: 0;
  }
  .accordionUpload {
    position: relative;
    width: 72px;
    margin-top: 15px;
    margin-left: pxem(16);
    .propic {
      width: inherit;

      .defaultAvatar {
        position: absolute;
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $--grey-200;
        font-size: pxem(20);
        color: $--grey-500;
      }
    }
    .accordionBtn {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
    }
    .uploadBtn {
      @include font-poppins();
      font-size: 12px;
      padding: 5px 10px;
      margin-top: 10px;
      margin-bottom: 20px;
      gap: 8px;
      color: $--grey-500;
      border: 1px solid;
      border-radius: 10px;
      background-color: $--grey-100;
      white-space: nowrap;
      :global(.ico) {
        font-size: 16px;
      }
    }
  }
  .expandIcon {
    &:global(.Mui-expanded) {
      transform: none;
    }
  }
}

.DragArea {
  padding-top: pxem(20);
}

.EditCard {
  min-width: pxrem(420);
  width: 100%;
  overflow: hidden;
  border-radius: pxem(10);
  box-shadow: 0 4px 4px rgba($--color-black, 0.25);

  @media screen and (max-width: 991px) {
    min-width: auto;
  }

  :global(.MuiTypography-root) {
    @include font-poppins();
    font-size: pxem(14);
    color: $--grey-500;
  }

  .wrapper {
    position: relative;
    // .banner {
    //   min-height: 140px;
    //   background-color: $--color-primary-dark;
    //   overflow: hidden;
    // }
    .banner {
      position: relative;
      min-height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $--color-primary-dark;
      padding: pxem(16 40);
      .name {
        bottom: 0;
        font-size: pxem(28);
        font-weight: 400;
      }
      :global(.ico-evatar) {
        color: $--color-white;
        font-size: pxem(60);

        @media screen and (max-width: 767px) {
          font-size: 50px;
        }
      }
    }
    .card {
      background-color: $--color-primary-dark;
      overflow: hidden;
      border-radius: pxem(10);
    }
    .default {
      @include font-poppins();
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: pxem(20);
      font-style: italic;
      color: $--color-white;
      padding: pxem(15 22, 20);
    }
    // .name {
    //   position: absolute;
    //   bottom: 0;
    //   font-size: pxem(36);
    //   font-weight: 400;
    //   padding: pxem(30, 36) calc(15px + #{pxem(20, 36)});
    // }
    // :global(.ico-evatar) {
    //   position: absolute;
    //   right: 0;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   margin-right: calc(15px + #{pxem(20, 100)});
    //   color: $--color-white;
    //   font-size: pxem(100);

    //   @media screen and (max-width: 767px) {
    //     font-size: 80px;
    //   }
    // }
    .head {
      @include font-poppins();
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      font-size: pxrem(12);
      padding: pxrem(15);
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: pxrem(10);
      z-index: 1;

      .left {
        color: $--grey-500;
        padding-left: pxem(20, 12);
      }
      .right {
        display: flex;
        align-items: center;
        gap: pxrem(8);
      }
      .more {
        font-size: pxem(20, 12);
        color: $--text-dark;
      }
    }
  }

  .info {
    padding: pxem(16 32);
    @media screen and (max-width: 575px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    .section {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .title {
      font-size: pxem(32);
      margin-bottom: pxem(10, 32);
    }
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label {
        font-size: pxem(18);
        font-weight: 700;
      }
      .price {
        font-size: pxem(28);
        font-weight: 500;
      }
    }
    .remarks {
      font-size: pxem(14);
      margin: pxem(25 0, 14);
    }
    .cancel {
      @include font-poppins();
      display: flex;
      color: $--color-danger;
      font-size: pxem(14);
      padding: pxem(10 30, 14);
      margin: auto;
      font-style: italic;
      border-radius: 60px;
      border: 1px solid $--color-danger;
      transition: $--hover;
      transition-property: color, background-color;

      &:hover {
        color: $--color-white;
        background-color: $--color-danger;
      }
    }
  }
}

.HalfRow {
  &.draggable {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .bar {
    position: relative;
    display: flex;
  }
  .HalfSelect {
    position: relative;
    @include font-poppins();
    font-size: 14px;
    background: $--background-grey;
    border-radius: 20px 0 0 20px;
    min-width: 130px;
    flex-shrink: 0;

    &:after {
      content: "";
      position: absolute;
      width: 1px;
      right: 0;
      top: 8px;
      bottom: 8px;
      background-color: $--grey-500;
    }

    :global(.MuiOutlinedInput-notchedOutline) {
      border: none;
    }
    :global(.MuiSelect-icon) {
      font-size: 10px;
      top: calc(50% - 10px);
      right: 15px;
      transition: transform $--hover;
    }
  }
  .HalfInput {
    flex-grow: 1;
    border-radius: 0 20px 20px 0;
    :global {
      .MuiInputLabel-root,
      .MuiInputBase-input {
        font-size: 14px;
      }
    }
  }
  .actions {
    position: absolute;
    display: flex;
    gap: 12px;
    top: 0;
    right: pxem(40);
    transform: translateY(-50%);

    @media screen and (max-width: 575px) {
      right: 20px;
    }
  }
  .socialLink {
    margin-top: 10px;
  }
  .labelDesc {
    display: block;
    font-size: 10px;
    padding: 8px 5px;
    color: $--grey-700;
  }
  .labelList {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
  .socialLabel {
    @include font-poppins();
    color: $--grey-500;
    font-size: 11px;
    line-height: 1;
    padding: 4px 12px;
    border: 1px solid;
    border-radius: 50px;
  }
}

.PhoneDemo {
  // @include font-roboto();
  position: sticky;
  box-sizing: border-box;
  // background-color: $--background-navy;
  top: 20px;
  width: clamp(340px, calc(500 / 955 * (100vh - 40px)), 500px);
  height: clamp(650px, calc(100vh - 40px), 955px);
  border: 10px solid #292929;
  border-radius: 50px;
  overflow: hidden;

  // @media screen and (max-width: 1319px) {
  @media screen and (max-width: 991px) {
    width: 100%;
    height: 0;
    padding-top: percentage(955 / 500);
    // width: 500px;
    // height: 955px;
  }

  @media screen and (max-width: 575px) {
    min-width: auto;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: percentage(216 / 450);
    height: 0;
    padding-top: percentage(30 / 450);
    background-color: #292929;
    border-radius: 0 0 40px 40px;
    z-index: 99;
  }

  .wrapper {
    position: absolute;
    inset: 0;
    height: 100%;
    overflow-y: scroll;
    border-radius: 40px;
    &::-webkit-scrollbar {
      display: none;
    }
    &.disabled {
      overflow: hidden;
    }
    > div {
      min-height: 100%;
    }
  }
}

.popper {
  :global(.MuiTooltip-tooltip) {
    background-color: $--grey-600;
  }
}
