@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.NotFound {
  height: 100vh;
  h1,
  h2 {
    margin-top: pxrem(40);
    margin-bottom: pxrem(40);
    line-height: 1;
  }
  .button {
    margin-top: pxem(60);
  }
}
