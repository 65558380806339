@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.SearchBar {
  :global {
    .MuiAutocomplete-root {
      .MuiInputAdornment-root,
      .MuiInputBase-input {
        @include font-poppins();
        font-weight: 300;
        color: $--grey-800;
        &::placeholder {
          opacity: 1;
        }
      }
      .MuiOutlinedInput-root {
        padding: pxem(10 12);
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: $--grey-500;
        border-radius: 0;
        transition: border-color $--hover;
      }

      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: $--color-primary-dark;
        }
      }
    }
  }
}
