@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.ModalWrapper {
  overflow: scroll;
}

.Modal {
  @include font-poppins();
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  outline: none;
  top: 50%;
  transform: translateY(-50%);

  .modalCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: $--color-white;
    border-radius: pxem(20);
  }

  &.basic {
    position: absolute;
    .modalCard {
      width: clamp(300px, calc(100% - 40px), 450px);
      // height: 570px;
    }
    .close {
      position: relative;
      inset: auto;
      margin-top: 10px;
      margin-bottom: -30px;
      margin-left: auto;
      margin-right: 10px;
    }
    .head {
      box-sizing: border-box;
      position: relative;
      text-align: center;
      width: 100%;
      padding: pxem(30);
      box-shadow: -8px 0px 8px 0px rgba($--text-dark, 0.25);

      .title {
        font-size: pxem(20);
        font-weight: 400;
      }

      .close {
        position: absolute;
        margin: 0;
        top: 50%;
        right: pxem(30, 24);
        transform: translateY(-50%);
      }
    }
    .content {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding-left: pxem(30);
      padding-right: pxem(30);
    }
    .foot {
      padding: pxem(30);
      margin-left: auto;
    }
  }

  &.fullScreen {
    top: 0;
    transform: none;

    .modalCard {
      width: clamp(300px, calc(100% - 40px), 80%);
      height: fit-content;
    }

    .close {
      position: relative;
      inset: auto;
      z-index: 1;
      margin-top: 10px;
      margin-right: 10px;
      margin-left: auto;
    }
  }

  &.confirm {
    .modalCard {
      min-width: 400px;
      min-height: 340px;
      justify-content: flex-end;
      padding: pxem(35);

      @media screen and (max-width: 575px) {
        min-width: auto;
        width: calc(100% - 40px);
        box-sizing: border-box;
      }
    }

    .text {
      text-align: center;
      margin: auto;
    }

    .title {
      font-size: pxem(24);
      font-weight: 400;
    }

    .remarks {
      margin-top: 10px;
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: pxem(18);
      font-weight: 700;
      padding: pxem(18, 18);
      border-radius: pxrem(100);
      border: 1px solid $--text-dark;
      transition: $--hover;
      transition-property: border-color, background-color;

      &.theme {
        border-color: $--color-primary-dark;
        background-color: $--color-primary-dark;
      }

      &:hover {
        border-color: lighten($--color-primary-dark, 5%);
        background-color: lighten($--color-primary-dark, 5%);
      }

      &.danger {
        color: $--color-white;
        border-color: $--color-danger;
        background-color: $--color-danger;
        &:hover {
          border-color: lighten($--color-danger, 5%);
          background-color: lighten($--color-danger, 5%);
        }
      }

      & + .button {
        margin-top: pxem(15, 18);
      }
    }
  }

  &.glass {
    top: 0;
    transform: none;
    padding: 0;
    backdrop-filter: blur(6px);

    .modalCard {
      width: 100vw;
      height: 100vh;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: rgba($--color-white, 0.3);
      color: $--color-white;

      .close {
        color: $--color-white;
      }
    }
  }
}
