//Base Font Size
$--font-base: 16px;
$--font-base-tablet: 15px;
$--font-base-mobile: 14px;

//Color Scheme
$--grey-0: #fff;
$--grey-100: #f7f7f7;
$--grey-200: #eaeaea;
$--grey-300: #dedede;
$--grey-400: #bebebe;
$--grey-500: #a9a9a9;
$--grey-600: #7d7d7d;
$--grey-700: #686868;
$--grey-800: #434343;
$--grey-900: #1f1f1f;
$--grey-1000: #000;

$--text-white: $--grey-100;
$--text-dark: #404d54;
// $--text-dark: #000;

$--text-title: #333;
$--text-paragraph: #505050;

$--color-white: $--grey-0;
$--color-black: $--grey-1000;
$--color-primary: #fff0b8;
$--color-primary-dark: #ffe173;
// $--color-primary: #0e122a;
// $--color-primary-dark: #0e122a;
$--color-secondary: #c6afff;
$--color-secondary-dark: #885df5;
$--color-tertiary: #a8e3ce;
$--color-quaternary: #b0c0d7;
$--color-info: #0052b4;
$--color-danger: #d80027;
$--color-success: #6da544;

$--background-primary: $--grey-500; //#a9a9a9
$--background-secondary: $--grey-300; //#dedede
$--background-tertiary: $--grey-200; //#eaeaea

$--background-button: $--grey-400; //bebebe
$--background-black: $--grey-900; //#1f1f1f
$--background-grey: #f5f5f5;
$--background-navy: #0e122a;
$--background-navy-secondary: #232840;
$--background-navy-light: #2a3158; //#555970;

$--blue-facebook: #1877f2;

$--step: #d9d9d9;
$--step-active: $--grey-800;

//Transition
$--hover: 0.3s ease;
$--slow: 1.3s ease;
$--shadow: 0 0 8px 0 rgba($--text-dark, 0.25);
