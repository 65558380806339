@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.Subscription {
  container-type: inline-size;
  padding-top: percentage(56 / 1440);
  padding-bottom: percentage(165 / 1440);

  :global(.MuiModal-root) & {
    padding: 0;
  }

  .title {
    @include font-poppins();
    text-align: center;
    text-transform: uppercase;
    color: $--text-title;
    font-size: pxem(40);
    font-weight: 700;

    @container (max-width: 991px) {
      font-size: pxrem(36);
    }
    @container (max-width: 575px) {
      font-size: pxrem(32);
    }
  }
  .tabs {
    margin-top: pxem(36);
    margin-bottom: pxem(36);
    :global(.MuiTabs-flexContainer) {
      max-width: pxrem(528);
    }
  }
  .cardWrapper {
    display: flex;
    flex-direction: column;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: pxem(56);

    @container (max-width: 991px) {
      gap: pxrem(24);
    }
    @container (max-width: 767px) {
      grid-template-columns: 1fr;
    }

    &.column {
      grid-auto-flow: column;
      grid-template-rows: 1fr 1fr;

      @container (max-width: 767px) {
        grid-auto-flow: row;
        grid-template-rows: 1fr;
      }
    }
  }
  .item {
    flex-grow: 1;
    display: block;
    box-sizing: border-box;
    @include font-poppins();
    padding: pxem(32 20);
    border: 1px solid $--grey-500;
    border-radius: pxem(20);
    transition: $--hover;
    transition-property: border-color, box-shadow;

    &:hover {
      border-color: $--color-primary-dark;
      box-shadow: 0 0 pxem(14) rgba($--color-black, 0.04);
    }

    .label {
      font-size: pxem(24);
      font-weight: 600;
      margin-bottom: pxem(16, 24);
    }
    .price {
      .sign {
        font-size: pxem(24);
        font-weight: 600;
      }
      .number {
        font-size: pxem(50);
        font-weight: 600;
      }
      .unit {
        font-size: pxem(24);
      }
    }
    .ul {
      margin-top: pxem(24);
      margin-bottom: pxem(40);
      display: flex;
      flex-direction: column;
      gap: pxem(24);

      .li {
        display: flex;
        align-items: center;
        :global(.MuiSvgIcon-root) {
          font-size: pxem(16);
          margin-right: pxem(12);
        }
      }
    }
  }
  .button {
    max-width: pxrem(350);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: pxem(60);
  }
  .try {
    white-space: nowrap;
    width: percentage(420 / 560);
    margin: pxem(40) auto;

    @container (max-width: 767px) {
      margin: pxem(20) auto;
    }
  }
  .about {
    display: flex;
    justify-content: center;
    margin: pxem(10 0 50);
    color: $--color-info;
    font-weight: 500;
    transition: color $--hover;
    &:hover {
      color: lighten($--color-info, 15%);
    }
  }
}
