@font-face {
  font-family: "icomoon";
  src: url("../../icomoon/fonts/icomoon.eot?rgf2a5");
  src: url("../../icomoon/fonts/icomoon.eot?rgf2a5#iefix")
      format("embedded-opentype"),
    url("../../icomoon/fonts/icomoon.ttf?rgf2a5") format("truetype"),
    url("../../icomoon/fonts/icomoon.woff?rgf2a5") format("woff"),
    // url("../../icomoon/fonts/icomoon.woff2?rgf2a5") format("woff2"),
    url("../../icomoon/fonts/icomoon.svg?rgf2a5#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ico {
  @include ico;
}

.ico-url:before {
  content: "\e930";
}
.ico-sms:before {
  content: "\e92e";
}
.ico-location:before {
  content: "\e92f";
}
.ico-contact:before {
  content: "\e92d";
}
.ico-user-solid:before {
  content: "\e92c";
}
.ico-link:before {
  content: "\e92b";
}
.ico-email:before {
  content: "\e929";
}
.ico-phone:before {
  content: "\e92a";
}
.ico-evatar:before {
  content: "\e928";
}
.ico-share:before {
  content: "\e925";
}
.ico-close:before {
  content: "\e926";
}
.ico-crown:before {
  content: "\e927";
}
.ico-more:before {
  content: "\e924";
}
.ico-user:before {
  content: "\e923";
}
.ico-arrow-down-strong:before {
  content: "\e91f";
}
.ico-arrow-right-strong:before {
  content: "\e920";
}
.ico-arrow-up-strong:before {
  content: "\e921";
}
.ico-arrow-left-strong:before {
  content: "\e922";
}
.ico-pointer-strong:before {
  content: "\e91e";
}
.ico-whatsapp:before {
  content: "\e91d";
}
.ico-arrow-down:before {
  content: "\e90e";
}
.ico-arrow-right:before {
  content: "\e90f";
}
.ico-arrow-up:before {
  content: "\e910";
}
.ico-arrow-left:before {
  content: "\e911";
}
.ico-search:before {
  content: "\e901";
}
.ico-visible:before {
  content: "\e906";
}
.ico-trash:before {
  content: "\e907";
}
.ico-shop:before {
  content: "\e908";
}
.ico-pointer:before {
  content: "\e909";
}
.ico-flip:before {
  content: "\e90a";
}
.ico-edit:before {
  content: "\e90b";
}
.ico-copy:before {
  content: "\e90c";
}
.ico-apple:before {
  content: "\e90d";
}
.ico-bag:before {
  content: "\e912";
}
.ico-camera:before {
  content: "\e913";
}
.ico-close-circle:before {
  content: "\e900";
}
.ico-drag:before {
  content: "\e914";
}
.ico-duplicate:before {
  content: "\e915";
}
.ico-google .path1:before {
  content: "\e916";
  color: rgb(66, 133, 244);
}
.ico-google .path2:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.ico-google .path3:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(251, 188, 5);
}
.ico-google .path4:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(234, 67, 53);
}
.ico-image:before {
  content: "\e91a";
}
.ico-menu:before {
  content: "\e91b";
}
.ico-settings:before {
  content: "\e91c";
}
.ico-twitter:before {
  content: "\e905";
}
.ico-instagram:before {
  content: "\e904";
}
.ico-linkedin:before {
  content: "\e902";
}
.ico-facebook:before {
  content: "\e903";
}
