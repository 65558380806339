@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.Header {
  --menu-decor-color: #{$--color-primary-dark};
  --menu-color: #{$--text-dark};

  position: relative;
  font-size: pxrem(16);
  padding: pxrem(12 0);
  width: 100vw;
  z-index: 999;
  background: none;
  box-shadow: none;
  transition: $--hover;
  transition-property: background-color, box-shadow;

  &.fixed {
    position: fixed;
    background: none;
    backdrop-filter: blur(6px);
    box-shadow: none;
  }

  &.white {
    --menu-color: #{$--grey-100};
  }

  &.shadow {
    box-shadow: $--shadow;
  }

  > :global(.container) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .aside {
    flex: 1;
    display: flex;
    align-items: center;

    &.right {
      justify-content: flex-end;
      gap: pxem(16);

      .redirect {
        font-size: 14px;
        color: var(--menu-color);
        white-space: nowrap;
        transition: color $--slow;
      }
    }

    .logo {
      display: flex;
      align-items: center;
      max-width: pxrem(120);
      max-height: pxrem(40);
      flex-shrink: 0;
      @media screen and (max-width: 767px) {
        max-width: pxrem(100);
      }
      @media screen and (max-width: 575px) {
        max-width: pxrem(80);
      }

      svg {
        path {
          transition: fill $--slow;
        }
      }
    }
  }

  .center {
    flex: 2;
  }

  .button {
    :global(.ico) {
      font-size: pxrem(28);
      // color: $--text-dark;
      color: var(--menu-color);
      transition: color $--slow;
    }
  }
}

.burger {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: pxem(30);
  // width: pxem(45);
  // height: pxem(45);
  flex-shrink: 0;
  border-radius: 50%;
  // border: 1px solid $--background-button;
  // background-color: $--color-white;
  // transition: $--hover;
  // transition-property: border-color, box-shadow;

  :global(.ico) {
    font-size: pxem(20);
    // color: $--text-dark;
    color: var(--menu-color);
    transition: color $--slow;
  }

  // .fixed & {
  //   border-color: $--color-white;
  //   box-shadow: $--shadow;
  // }

  @media screen and (min-width: 992px) {
    display: none;
  }

  @media screen and (max-width: 575px) {
    margin-right: pxrem(20);
    // width: pxrem(35);
    // height: pxrem(35);
    :global(.ico) {
      font-size: pxrem(16);
    }
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: pxem(20);
  list-style-type: none;
  @include font-poppins();

  @media screen and (max-width: 1199px) {
    gap: pxrem(6);
  }

  @media screen and (max-width: 991px) {
    display: none;
  }

  > li {
    font-size: pxem(14);
  }

  .link {
    color: var(--menu-color);
    display: block;
    white-space: nowrap;
    position: relative;
    padding: pxem(13);
    transition: color $--slow;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: pxrem(0);
      height: pxrem(3);
      background-color: var(--menu-decor-color);
      transition: width $--hover;
    }

    &:hover,
    &:focus,
    &:global(.active) {
      &:after {
        width: 100%;
      }
    }
  }

  & + .Account {
    margin-left: pxem(100);

    @media screen and (max-width: 1199px) {
      margin-left: pxrem(30);
    }
  }
}

.Account {
  position: relative;
  flex-shrink: 0;

  @media screen and (max-width: 575px) {
    display: none;
  }

  .name {
    max-width: pxrem(140);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 575px) {
      max-width: pxrem(90);
    }
  }

  .button {
    &:global(.MuiButtonBase-root) {
      @include font-poppins();
      text-transform: none;
      color: $--text-title;
      font-size: pxrem(14);
      font-weight: 400;
      line-height: pxem(24, 14);
      padding: pxem(8, 14);
      display: flex;
      justify-content: space-between;
      // border: 2px solid $--step;
      border-radius: pxem(20, 14);
      transition: $--hover;
      transition-property: border-color, background-color;

      .MuiTouchRipple-child {
        background-color: $--color-primary-dark;
      }

      &:hover {
        background-color: rgba($--color-primary-dark, 0.04);
      }

      &:hover,
      &:focus,
      &.Mui-focused {
        border-color: $--color-primary-dark;
      }

      &[aria-expanded="true"] {
        .MuiButton-endIcon {
          transform: rotate(180deg);
        }
      }
    }
    :global {
      .MuiAvatar-root {
        @include font-poppins();
        // font-size: pxem(18, 14);
        width: pxem(20, 14);
        height: pxem(20, 14);
        margin-right: pxem(8, 14);
        background-color: $--color-primary-dark;
      }
      .MuiButton-endIcon {
        transition: transform $--hover;
        margin-left: pxem(10, 14);
        margin-right: 0;
        .ico {
          font-size: pxem(8, 14);
        }
      }
    }
  }

  .dropdown {
    width: 100%;

    :global {
      .MuiMenuItem-root {
        @include font-poppins();
        font-size: pxem(14);
        font-weight: 300;
        color: $--text-dark;
        line-height: (17 / 14);
        transition: background-color $--hover;
        white-space: break-spaces;
        justify-content: center;
        text-align: center;

        &:hover {
          background-color: $--color-primary;
        }
      }
    }
  }
}
