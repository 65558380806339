@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.Card {
  @media screen and (max-width: 767px) {
    max-width: pxrem(600);
    margin: auto;
  }

  .wrapper {
    display: flex;
    border: 1px solid $--background-secondary;

    &.hover {
      transition: transform $--hover;
      &:hover {
        transform: scale(1.02);
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      max-width: pxrem(600);
      margin: auto;
    }
  }

  .placeholder {
    flex: 0 0 percentage(600 / 1125);
    display: flex;
    align-items: center;
  }

  .text {
    flex: 0 0 percentage(525 / 1125);
    display: flex;
    flex-direction: column;

    .title,
    .content {
      padding: pxrem(24);
    }

    .title {
      border-bottom: 1px solid $--background-secondary;
    }

    .content {
      @include font-poppins();
      font-size: 14px;
      line-height: 2;
    }
  }
}

.DashboardCard {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 4px rgba($--color-black, 0.25);
  @include font-poppins();
  background-color: $--color-white;
  transition: transform $--hover;

  .head {
    @include font-poppins();
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: pxrem(12);
    padding: pxrem(5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: pxrem(10);
    z-index: 1;

    .left {
      color: $--grey-500;
      padding-left: pxem(20, 12);
    }
    .right {
      display: flex;
      align-items: center;
      // gap: pxrem(8);
    }
    .shareBtn {
      @include font-poppins();
      color: $--text-dark;
      background-color: $--color-white;
      border-radius: 30px;
      padding: pxrem(5 10);
      :global(.ico) {
        font-size: pxrem(16);
        margin-right: pxem(8, 16);
      }
    }
    .more {
      font-size: pxem(20, 12);
      color: $--grey-500;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    .cardwrapper {
      width: 100%;
    }
    .cardholder {
      position: relative;
      background-color: $--background-navy;
    }
    .card {
      position: relative;
      overflow: hidden;

      &.gradient {
        mask-image: linear-gradient(
          to top,
          rgba($--background-black, 0) 0%,
          rgba($--background-black, 0.2) 10%,
          $--background-black 55%
        );
      }
      .defaultIcon {
        position: absolute;
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: $--color-primary-dark;
        background-color: $--background-navy;

        :global(.ico) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 60px;
          // color: $--grey-900;
          color: $--color-white;
          z-index: 1;

          text-shadow: 3px -3px rgba($--color-white, 0.3);
        }

        &::before {
          content: "";
          position: absolute;
          inset: 0;
          background: linear-gradient(
            to top,
            rgba($--color-white, 0) 0%,
            rgba($--color-white, 0.15) 100%
          );
          pointer-events: none;
        }
      }
    }
    .default {
      @include font-poppins();
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: pxem(16);
      font-style: italic;
      color: $--color-white;
      padding: pxem(15 22, 20);
    }
  }

  .more {
    position: absolute;
    font-size: pxem(24);
    color: $--grey-500;
    top: pxrem(5);
    right: pxrem(5);
    z-index: 1;
  }

  .image {
    // background-color: $--color-primary-dark;
    background-color: $--grey-300;
    border-radius: 16px;
  }

  .name {
    @include line-clamp(2);
    flex-shrink: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin: pxem(15, 16);
  }

  .dropdown {
    position: absolute !important;
    transform: none !important;
    left: auto !important;
    right: 0;
    z-index: 1;
    max-width: pxrem(144);
    width: 100%;

    :global {
      .MuiPaper-root {
        border-radius: 0;
      }
      .MuiList-root {
        padding: 0;
      }
      .MuiMenuItem-root {
        @include font-poppins();
        font-weight: 300;
        color: $--text-dark;
        line-height: (30 / 16);
        white-space: break-spaces;
        justify-content: center;
        text-align: center;
        transition: $--hover;
        transition-property: background-color, color;
        &:hover {
          color: $--color-white;
          background-color: $--color-info;
        }
      }
    }
  }

  &:hover {
    transform: scale(1.05);
  }

  // &:nth-child(2n-1) {
  //   .card {
  //     background-color: $--color-primary-dark;
  //   }
  // }
  // &:nth-child(2n) {
  //   .card {
  //     background-color: $--color-secondary-dark;
  //   }
  // }
}

.upload {
  margin: pxem(32 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .label {
    color: $--grey-500;
    font-size: pxem(14);
    line-height: (35 / 14);
  }
  .placeholder {
    width: pxrem(200);
    border-radius: pxem(20);
    overflow: hidden;
    :global(.avataredit) {
      canvas {
        border-radius: pxem(20);
      }
    }
    .dropzone {
      background-color: $--grey-200;
      :global(.MuiSvgIcon-root) {
        font-size: pxem(60);
      }
    }
  }
}

.ProfileCard {
  border-radius: 16px;
  transition: transform $--hover !important;

  .head {
    @include font-poppins();
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: pxrem(12);
    padding: pxrem(5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: pxrem(10);
    z-index: 1;

    .left {
      color: $--grey-500;
      padding-left: pxem(20, 12);
    }
    .right {
      display: flex;
      align-items: center;
      // gap: pxrem(8);
    }
    .shareBtn {
      @include font-poppins();
      color: $--text-dark;
      background-color: $--color-white;
      border-radius: 30px;
      padding: pxrem(5 10);
      :global(.ico) {
        font-size: pxrem(16);
        margin-right: pxem(8, 16);
      }
    }
    .more {
      font-size: pxem(20, 12);
      color: $--text-dark;
    }
  }

  .wrapper {
    position: relative;
    .cardholder {
      position: relative;
    }
    .card {
      overflow: hidden;
      border-radius: pxem(10);
    }
    .default {
      @include font-poppins();
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: pxem(20);
      font-style: italic;
      color: $--color-white;
      padding: pxem(15 22, 20);
    }
  }

  .info {
    @include font-poppins();
    padding: pxem(32);

    .name {
      font-size: pxem(18);
      font-weight: 400;
    }
    .position {
      font-size: pxem(18);
    }
    .department {
      font-size: pxem(18);
      font-weight: 700;
    }
    .company {
      font-size: pxem(24);
      font-style: italic;
    }
  }

  &:nth-child(2n-1) {
    .card {
      background-color: $--color-primary-dark;
    }
  }
  &:nth-child(2n) {
    .card {
      background-color: $--color-secondary-dark;
    }
    .head {
      .more {
        color: $--color-white;
      }
    }
  }

  .dropdown {
    position: absolute !important;
    transform: none !important;
    left: auto !important;
    right: 0;
    z-index: 1;
    max-width: pxrem(144);
    width: 100%;

    :global {
      .MuiPaper-root {
        border-radius: 0;
      }
      .MuiList-root {
        padding: 0;
      }
      .MuiMenuItem-root {
        @include font-poppins();
        font-weight: 300;
        color: $--text-dark;
        line-height: (30 / 16);
        white-space: break-spaces;
        justify-content: center;
        text-align: center;
        transition: $--hover;
        transition-property: background-color, color;
        &:hover {
          color: $--color-white;
          background-color: $--color-info;
        }
      }
    }
  }

  &:hover {
    transform: scale(1.05);
  }
}
