@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.Bottom {
  padding-top: pxem(40);
  padding-bottom: pxem(40);

  &.secondary {
    background-color: $--grey-100;
    padding-top: percentage(128 / 1440);
    padding-bottom: percentage(128 / 1440);
  }

  // > :global(.container) {
  //   padding-left: 80px;
  //   padding-right: 80px;
  // }

  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: percentage(92 / 1440);
    padding-right: percentage(92 / 1440);
    background-color: $--color-white;
    border-radius: pxem(30);
    box-shadow: 0 0 pxem(8) rgba($--color-black, 0.15);

    @media screen and (max-width: 575px) {
      flex-direction: column;
    }
  }
  h6 {
    font-weight: 500;
    margin: pxem(16 0);
    @media screen and (max-width: 575px) {
      margin-bottom: 0;
    }
  }
  .button {
    white-space: nowrap;
    font-size: pxem(20);
    margin-top: pxem(20);
    margin-bottom: pxem(20);
    @media screen and (max-width: 991px) {
      font-size: pxrem(16);
    }
  }
}
