@mixin font-roboto {
  font-family: "Roboto", sans-serif;
}

@mixin font-poppins {
  font-family: "Poppins", sans-serif;
}

@mixin line-clamp($number) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $number;
  overflow: hidden;
}

@mixin ico {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@function pxrem($value, $base: strip-unit($--font-base)) {
  $remValue: ();
  @each $arg in $value {
    $remValue: append($remValue, unquote($arg / $base + "rem"), "space");
  }
  @return $remValue;
}

@function pxem($value, $base: strip-unit($--font-base)) {
  $emValue: ();
  @each $arg in $value {
    $emValue: append($emValue, unquote($arg / $base + "em"), "space");
  }
  @return $emValue;
}
