@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.Login {
  min-height: 80vh;
  display: flex;
  align-items: center;
  @include font-poppins();
  margin-bottom: pxrem(100);

  > :global(.container) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  p,
  a {
    font-size: pxem(16);
  }

  .title {
    text-align: center;
    font-size: pxem(24);
    font-weight: 700;
    margin-bottom: pxem(40, 24);
  }

  .intro {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: percentage(72 / 1440);

    :global(.MuiInput) {
      &:not(:first-child) {
        margin-top: pxem(25);
      }
    }
  }

  .semibold {
    font-weight: 600;
  }

  .ul {
    counter-reset: step;
    display: flex;
    flex-direction: column;
    gap: pxem(38);
    margin-bottom: pxem(48);

    .li {
      display: flex;
      align-items: center;
      font-size: pxem(24);
      font-weight: 500;

      &:before {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @include font-poppins();
        color: $--text-dark;
        font-size: pxem(24, 24);
        font-weight: 700;
        margin-right: pxem(35, 24);
        counter-increment: step;
        content: counter(step);
        width: pxem(42, 24);
        height: pxem(42, 24);
        background-color: $--color-primary-dark;
        border-radius: 50%;
      }
    }
  }

  .link {
    @include font-poppins();
    transition: color $--hover;
    &:hover {
      color: darken($--color-primary-dark, 25%);
    }
  }

  .button {
    margin: pxem(32) 0;
    font-weight: 700;
  }

  .fullwidth {
    max-width: pxrem(450);
    width: 100%;
  }
}
