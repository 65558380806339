:root {
  // @include font-roboto();
  @include font-poppins();
  font-weight: 300;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 0s ease-in-out 9999s;
}
// body {
//   margin: 0;

//   #root {
//     display: flex;
//     flex-direction: column;
//     min-height: 100vh;
//     overflow: hidden;
//   }
// }

html,
body {
  margin: 0;
  overscroll-behavior: none;

  #root,
  .MuiModal-root {
    font-size: $--font-base;
    @media screen and (max-width: 1199px) {
      font-size: $--font-base-tablet;
    }
    @media screen and (max-width: 767px) {
      font-size: $--font-base-mobile;
    }
  }
}

footer {
  margin-top: auto;
}

h1,
.h1 {
  @include font-poppins();
  font-size: pxrem(60);
  color: $--text-title;
  font-weight: 700;

  // @media screen and (max-width: 1439px) {
  //   font-size: pxrem(60);
  // }
  @media screen and (max-width: 1199px) {
    font-size: pxrem(50);
  }
  @media screen and (max-width: 991px) {
    font-size: pxrem(40);
  }
  // @media screen and (max-width: 575px) {
  //   font-size: pxrem(40);
  // }
}

h2,
.h2 {
  @include font-poppins();
  color: $--text-title;
  font-size: pxrem(60);
  font-weight: 700;

  @media screen and (max-width: 1199px) {
    font-size: pxrem(50);
  }
  @media screen and (max-width: 991px) {
    font-size: pxrem(45);
    margin-top: pxrem(20);
    margin-bottom: pxrem(20);
  }
  @media screen and (max-width: 575px) {
    font-size: pxrem(40);
    margin: 0;
  }
}

h3,
.h3 {
  @include font-poppins();
  color: $--text-title;
  font-size: pxrem(36);
  font-weight: 700;

  @media screen and (max-width: 991px) {
    font-size: pxrem(34);
  }
  @media screen and (max-width: 575px) {
    font-size: pxrem(32);
  }
}

h4,
.h4 {
  @include font-poppins();
  color: $--text-title;
  font-size: pxrem(32);
  font-weight: 700;
}

h5,
.h5 {
  @include font-poppins();
  color: $--text-title;
  font-size: pxrem(28);
  font-weight: 700;
}

h6,
.h6 {
  @include font-poppins();
  color: $--text-title;
  font-size: pxrem(24);
  font-weight: 700;
}

a {
  color: $--text-dark;
  text-decoration: none;
}

p,
.p {
  margin: 0;
  color: $--text-paragraph;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;

  > li {
    font-size: 0.625em;
    > span {
      font-size: 1.6em;
    }
  }
}

input,
textarea {
  @include font-poppins();
  font-weight: 300;
}

img {
  width: 100%;
  height: auto;
}

button,
.btn {
  // @include font-roboto();
  @include font-poppins();
  font-size: inherit;
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
}

.MuiTooltip-tooltip.MuiTooltip-tooltip,
.MuiSnackbarContent-message.MuiSnackbarContent-message {
  @include font-poppins();
}
