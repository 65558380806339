@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.wave {
  position: absolute;
  top: 50%;
  will-change: transform;
  transform: scale(10) rotate(-14deg);
  z-index: -1;
  @media screen and (max-width: 767px) {
    transform: scale(5) rotate(45deg);
  }
}

.Contact {
  position: relative;
  overflow: hidden;
  padding-top: pxem(70);
  padding-bottom: pxem(100);
  color: $--text-dark;

  :global(.h4) {
    color: $--text-dark;
  }

  > :global(.container) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: pxem(20);

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: pxrem(40);
    }
  }

  .info {
    @include font-poppins();
    @media screen and (max-width: 767px) {
      text-align: center;
    }
    .item {
      .label {
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-weight: 700;
        line-height: pxem(30);
      }
      .value {
        display: flex;
        flex-direction: column;
      }

      &:not(:first-child) {
        margin-top: pxem(20);
      }
    }
  }

  .message {
    .form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: pxem(26);

      @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
      }

      .fullwidth {
        grid-column: span 2;

        @media screen and (max-width: 767px) {
          grid-column: auto;
        }
      }

      .input {
        border: none;
        flex: 1;
        color: $--text-dark;

        :global(.MuiInputBase-root) {
          @include font-poppins();
          background: none;
          border-radius: 0;
          font-size: pxem(16);
          padding: pxem(16 8);

          &:global(.Mui-error) {
            :global(.MuiInputBase-input) {
              &::placeholder {
                color: $--color-danger;
                opacity: 1;
              }
            }
          }
        }
        :global(.MuiInputBase-input) {
          padding: 0;
        }
        :global(.MuiOutlinedInput-notchedOutline) {
          transition: border-color $--hover;
        }
        :global(.MuiInputBase-inputMultiline) {
          max-height: pxem(120);
          min-height: pxem(120);
        }
      }

      .submit {
        margin-left: auto;
      }
    }
  }
}
