@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.Button {
  // @include font-roboto();
  @include font-poppins();
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: pxrem(28 54);
  color: $--text-dark;
  font-size: 14px;
  font-weight: 400;
  background-color: $--color-primary-dark;
  border-radius: pxrem(100);
  transition: background-color $--hover;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    padding: pxrem(20 44);
  }

  &:hover {
    background-color: lighten($--color-primary-dark, 8%);
  }

  &.small {
    padding-top: pxrem(13);
    padding-bottom: pxrem(13);
  }

  &.fullWidth {
    width: 100%;
  }
}
