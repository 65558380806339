@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.Placeholder {
  position: relative;
  width: 100%;

  .frame {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 10%;
    overflow: hidden;

    &.circle {
      border-radius: 50%;
    }

    &.rounded {
      border-radius: 10px;
    }
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.centered {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.contained {
      object-fit: contain;
    }
  }

  :global(.dropzone),
  :global(.avataredit),
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }

  :global {
    .dropzone {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .indicator {
        @include font-poppins();
        padding: pxem(0 32);
        color: $--background-primary;
        .MuiSvgIcon-root {
          font-size: pxrem(80);
        }
        transition: opacity $--hover;

        @media (hover: hover) {
          opacity: 0;
        }
      }
      &:hover,
      &:focus,
      &:focus-within {
        .indicator {
          opacity: 1;
        }
      }
    }
  }
}
