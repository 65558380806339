@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.title {
  @include font-poppins();
  margin-bottom: pxem(10);
}

.InfoBox {
  @include font-poppins();
  position: relative;
  display: flex;
  padding: pxem(20 15);
  background-color: $--color-white;
  border: 1px solid $--grey-300;
  border-radius: pxem(20);

  @media screen and (max-width: 575px) {
    padding-top: pxrem(12);
    padding-bottom: pxrem(12);
  }

  .field {
    display: flex;
    gap: pxem(15);
    width: 100%;

    & + .field {
      margin-top: pxem(15);
    }

    > div {
      flex: 1;

      & + div {
        flex: 2;
        @media screen and (max-width: 575px) {
          flex: 1;
        }
      }
    }
  }

  .content {
    flex-grow: 1;
    font-size: pxem(14);
    color: $--text-dark;
  }

  .value {
    display: flex;
    flex-direction: column;
  }

  .strong {
    font-size: pxrem(16);
    color: $--color-black;
    font-weight: 700;
  }

  .change {
    @include font-poppins();
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: pxem(14);
    padding: pxem(0 10, 14);
    margin-top: pxrem(-5);
    background-color: $--grey-200;
    transition: background-color $--hover;
    line-height: (24 / 14);

    &:hover {
      background-color: darken($--grey-200, 5%);
    }

    @media screen and (max-width: 575px) {
      position: relative;
      inset: auto;
      margin-left: auto;
      margin-bottom: pxrem(8);
    }
  }
}
