@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.CartIcon {
  &:global(.MuiButton.MuiButtonBase-root) {
    font-size: pxem(20);
    color: var(--menu-color);
    transition: color $--slow;

    :global(.ico) {
      margin-right: pxem(8, 20);
    }
    .badge {
      font-size: pxem(17, 20);
      font-weight: 300;
    }
  }
}

.Cart {
  &:global(.MuiPaper-root) {
    font-size: 16px;
    width: pxrem(380);
    height: 100vh;
    background-color: $--grey-100;
    @include font-poppins();
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: pxem(12);
    padding: pxem(16, 12);
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid $--grey-400;
  }

  .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $--text-dark;
    flex-grow: 1;
    :global(.ico-bag) {
      font-size: pxem(55);
      margin-top: pxem(20, 55);
    }
  }

  .column {
    display: flex;
    justify-content: space-between;
  }

  .list {
    padding: pxem(16);
    // max-height: percentage(500 / 900);
    overflow-y: scroll;
    flex-grow: 1;
    .item {
      min-height: 0;
      flex: 1 1 1px;
      & + .item {
        margin-top: pxem(24);
      }
      .column {
        gap: pxem(20);
        & + .column {
          margin-top: pxem(20);
        }
        .quantity {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: $--color-primary;
          border-radius: pxrem(50);
          overflow: hidden;

          :global(.MuiButton) {
            padding: pxem(4);
            border-radius: 0;
            :global(.MuiSvgIcon-root) {
              font-size: pxem(14);
            }
          }
          .number {
            width: pxem(28);
            font-size: pxem(12);
            text-align: center;
          }
        }
        .thumbnail {
          flex-shrink: 0;
          width: pxem(75);
          border-radius: pxem(6);
          overflow: hidden;
        }
        .info {
          display: flex;
          align-items: center;
          font-size: pxem(14);
          font-weight: 300;
          .texture {
            color: $--grey-600;
          }
        }
        .price {
          flex-shrink: 0;
          font-size: pxem(12);
          font-weight: 500;
        }
        .remove {
          font-size: pxem(10);
          font-weight: 300;
          text-decoration: underline;
        }
      }
    }
  }

  .summary {
    position: relative;
    padding: pxem(16);
    padding-bottom: 0;
    .column {
      font-size: pxem(14);
    }
    .shipping {
      :global {
        .MuiOutlinedInput-notchedOutline {
          display: none;
        }
        .MuiSelect-select {
          @include font-poppins();
          font-size: pxem(10);
          color: $--grey-600;
          text-align: left;
          padding-left: 0;
          padding-top: 12px;
          padding-bottom: 12px;
          min-height: 16px;
        }
        .MuiSelect-icon {
          display: flex;
          top: calc(50% - 3px);
          right: 6px;
          color: $--text-title;
          transition: transform $--hover;
          transform: rotate(0);
          transform-origin: center;

          &.MuiSelect-iconOpen {
            transform: rotate(180deg);
          }
          .ico {
            font-size: 8px;
          }
        }
      }
    }
  }

  .foot {
    border-top: 1px solid $--grey-500;
    background-color: $--color-white;
    font-size: pxem(14);
    font-weight: 500;
    padding: pxem(16, 14);
    .checkout {
      margin: pxem(28 0, 14);
      padding: pxem(15, 14);
    }
  }
}

.shippingMenu {
  :global {
    .MuiMenu-paper {
      border: 1px solid $--grey-500;
      background-color: $--color-white;
      border-radius: 0;
      box-shadow: none;
    }
    .MuiMenuItem-root {
      @include font-poppins();
      font-size: pxem(10);
      font-weight: 300;
      color: $--text-dark;
      &.Mui-selected {
        background-color: rgba($--color-primary-dark, 0.5);
      }
    }
  }
}
