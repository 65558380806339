@import "assets/styles/base/variables";
@import "assets/styles/base/utils";
.Footer {
  border-top: 1px solid $--grey-200;
  padding-top: pxem(20);
  padding-bottom: pxem(20);

  > :global(.container) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 575px) {
      flex-direction: column;
    }
  }

  .copyright {
    @include font-poppins();
    font-size: pxrem(12);
  }

  .logo {
    img {
      max-width: pxrem(90);
      margin: pxem(12 0);
    }
    @media screen and (max-width: 575px) {
      order: 1;
      margin-top: 16px;
      align-self: flex-start;
    }
  }

  .social {
    display: flex;
    gap: pxem(25);
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: pxem(32);
    height: pxem(32);
    background-color: $--text-dark;
    border-radius: 50%;

    :global(.ico) {
      color: $--color-white;
    }
  }
}
