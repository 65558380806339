$--padding-container: pxem(215);
$--padding-container-laptop: pxrem(45);
$--padding-container-tablet: pxrem(30);
$--padding-container-mobile: pxrem(26);

.container {
  max-width: pxrem(1200);
  margin: 0 auto;
  padding-left: $--padding-container;
  padding-right: $--padding-container;

  // &--narrow {
  //   padding-left: calc(#{$--padding-container} / 3);
  //   padding-right: calc(#{$--padding-container} / 3);
  // }

  @media screen and (max-width: 1199px) {
    padding-left: $--padding-container-laptop;
    padding-right: $--padding-container-laptop;
  }

  @media screen and (max-width: 991px) {
    padding-left: $--padding-container-tablet;
    padding-right: $--padding-container-tablet;
  }

  @media screen and (max-width: 575px) {
    padding-left: $--padding-container-mobile;
    padding-right: $--padding-container-mobile;
  }
}

.hoverable {
  display: none;
  @media (hover: hover) {
    display: block;
  }
}

.touchscreen {
  display: block;
  @media (hover: hover) {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.embedded-video {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  border-radius: 12px;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.animated-wave {
  --wave-color: #{rgba($--color-white, 0.1)};

  &.dark {
    --wave-color: #{rgba($--background-navy, 0.05)};
  }

  &,
  &:before,
  &:after {
    content: "";
    position: absolute;
    // top: 50%;
    left: 50%;
    // width: 250vw;
    // height: 250vw;
    // margin-left: -125vw;
    // box-shadow: inset 0 0 10vw var(--wave-color);
    width: 400px;
    height: 400px;
    margin-left: -200px;
    box-shadow: inset 0 0 10px var(--wave-color);
    transform-origin: 50% 50%;
    background-color: transparent;
    border-radius: 38% 42%;
    animation: spin 50s infinite linear;
    mix-blend-mode: multiply;
    pointer-events: none;
    will-change: transform;
  }

  &:before {
    width: 105%;
    height: 95%;
    // margin-top: -125vw;
    // box-shadow: inset 0 0 10vw var(--wave-color);
    margin-left: -200px;
    box-shadow: inset 0 0 10px var(--wave-color);
    transform-origin: 49% 51%;
    border-radius: 40% 38%;
    animation: spin 55s infinite linear;
  }

  &:after {
    width: 102%;
    height: 98%;
    // margin-top: -125vw;
    // box-shadow: inset 0 0 10vw var(--wave-color);
    margin-left: -200px;
    box-shadow: inset 0 0 10px var(--wave-color);
    transform-origin: 51% 49%;
    border-radius: 48% 42%;
    animation: spin 60s infinite linear;
  }
}

.MuiButton {
  &.MuiButtonBase-root {
    display: flex;
    align-items: center;
    min-width: auto;
    color: $--text-dark;
    font-size: pxem(16);
    font-weight: 400;
    text-transform: none;
    border-radius: pxem(10);

    &:hover {
      background-color: rgba($--color-primary-dark, 0.25);
    }
    .MuiTouchRipple-child {
      background-color: $--color-primary-dark;
    }
  }
}

.MuiRadioGroup {
  .MuiFormControlLabel-root {
    border: 1px solid $--grey-300;
    margin-left: 0;
    margin-right: 0;
    padding: pxem(12 16);
    border-radius: pxem(20);
    transition: border-color $--hover;

    & + .MuiFormControlLabel-root {
      margin-top: pxem(12);
    }

    &.Mui-checked {
      border-color: $--color-primary-dark;
    }
  }
  .MuiRadio-root {
    order: 1;
    &.Mui-checked {
      color: $--color-primary-dark;
    }
  }
  .MuiFormControlLabel-label {
    flex-grow: 1;
  }
}

.MuiNoRadioGroup {
  &.MuiFormGroup-root {
    display: block;
  }
  .MuiFormControlLabel-root {
    border: 2px solid $--grey-300;
    height: fit-content;
    margin-left: 0;
    margin-right: 0;
    padding: pxem(16) 16px;
    border-radius: pxem(20);
    transition: border-color $--hover;

    &.Mui-checked {
      border-color: $--color-primary-dark;
    }
  }
  .MuiTypography-root {
    display: flex;
    justify-content: space-between;
    @include font-poppins();
    font-size: pxem(18);
    color: $--text-dark;
  }
  .Mui-centered {
    .MuiTypography-root {
      justify-content: center;
    }
  }
  .MuiRadio-root {
    position: absolute;
    opacity: 0;
  }
  .MuiFormControlLabel-label {
    flex-grow: 1;
  }
  .MuiAccordion-root {
    width: 100%;
    box-shadow: none;
    .MuiAccordionSummary-root {
      padding: 0;
      min-height: auto;
      .MuiAccordionSummary-content {
        margin: 0;
        display: flex;
        justify-content: space-between;
        &.Mui-expanded {
          margin-bottom: 10px;
        }
      }
    }
    .MuiAccordionDetails-root {
      padding: 0;
    }
  }
}

.PrimaryTabs {
  margin-bottom: pxem(24);

  .MuiTabs-flexContainer {
    max-width: pxrem(825);
    margin: auto;
  }
  .MuiTab-root {
    @include font-poppins();
    font-size: pxem(18);
    text-transform: none;
    color: $--grey-400;
    background-color: $--color-white;
    border: 1px solid $--grey-200;
    transition: $--hover;
    transition-property: color, border-color, background-color;

    @media screen and (min-width: 575px) {
      min-width: 160px;
    }

    &.Mui-selected {
      color: $--text-dark;
      border-color: $--color-primary-dark;
      background-color: $--color-primary-dark;
    }

    &:first-child {
      border-radius: pxem(10 0 0 10);
    }

    &:last-child {
      border-radius: pxem(0 10 10 0);
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
}

.SecondaryTabs {
  margin-bottom: pxem(60);

  .MuiTabs-flexContainer {
    @media screen and (min-width: 576px) {
      gap: pxem(30);
    }
  }
  .MuiTab-root {
    @include font-poppins();
    text-transform: none;
    color: $--background-primary;
    transition: $--hover;
    transition-property: color;

    &.Mui-selected {
      color: $--color-black;
    }

    &:first-child {
      border-radius: pxem(10 0 0 10);
    }

    &:last-child {
      border-radius: pxem(0 10 10 0);
    }
  }
  .MuiTabs-indicator {
    background-color: $--color-primary-dark;
  }
}

.MuiSelect {
  color: $--text-title;
  border-radius: pxem(20);

  .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: $--step;
    border-radius: pxem(20);
    transition: border-color $--hover;
  }
  .MuiSelect-select {
    color: $--text-title;
    text-align: left;
    padding-left: pxem(25, 18);
    min-width: pxem(43, 18);
  }
  .MuiSelect-icon {
    top: calc(50% - #{pxem(10)});
    right: pxem(11);
    color: inherit;
    transition: transform $--hover;
    transform: rotate(0);
    transform-origin: center;

    &.MuiSelect-iconOpen {
      transform: rotate(180deg);
    }
    .ico {
      font-size: pxem(12);
    }
  }
  &.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: $--color-primary-dark;
    }
  }
}

.MuiSelectMenu {
  .MuiMenu-paper {
    background-color: rgba($--text-dark, 0.9);
    border-radius: pxem(20);
  }
  .MuiMenuItem-root {
    @include font-poppins();
    color: $--color-white;
    &.Mui-selected {
      background-color: rgba($--text-white, 0.18);
    }
  }
}

.MuiInput {
  font-size: pxem(18);
  color: $--text-title;
  .MuiInputAdornment-root {
    .MuiSvgIcon-root {
      cursor: pointer;
    }
  }
  .MuiButtonBase-root {
    @include font-poppins();
    color: $--text-title;
    font-size: inherit;
    text-transform: none;
    font-weight: 400;
    line-height: pxem(24, 18);
    padding: pxem(18, 18) 16px;
    display: flex;
    justify-content: space-between;
    border: 2px solid $--step;
    border-radius: pxem(20, 18);
    transition: $--hover;
    transition-property: border-color, background-color;

    .MuiTouchRipple-child {
      background-color: $--color-primary-dark;
    }

    &:hover {
      background-color: rgba($--color-primary-dark, 0.04);
    }

    &:hover,
    &:focus,
    &.Mui-focused {
      border-color: $--color-primary-dark;
    }
  }
  .MuiTextField-root,
  .MuiFormControl-root {
    .MuiInputLabel-root {
      @include font-poppins();
      color: inherit;
      font-size: inherit;
      line-height: pxem(24, 18);
      transform: translate(16px, pxem(18, 18)) scale(1);

      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(16px, pxem(-9, 18)) scale(0.75);
      }

      &.Mui-error {
        color: $--color-danger;
      }
    }
    .MuiInputBase-root {
      @include font-poppins();
      color: $--text-title;
      font-size: inherit;
      line-height: pxem(24, 18);
      .MuiInputBase-input {
        padding: pxem(18, 18) 16px;
        min-height: auto;
        height: auto;
      }
      .MuiOutlinedInput-notchedOutline {
        border-width: 2px;
        border-radius: pxem(20);
        border-color: $--step;
        transition: border-color $--hover;
      }
      &:hover,
      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: $--color-primary-dark;
        }
      }
      &.Mui-error {
        .MuiOutlinedInput-notchedOutline {
          border-color: $--color-danger;
        }
      }
    }
  }
}

.MuiCalendarPicker-root {
  .MuiPickersDay-today {
    &:not(.Mui-selected) {
      border-color: $--grey-500;
    }
  }
  .MuiPickersDay-root {
    color: $--grey-700;
    &:focus,
    &.Mui-selected,
    &:focus.Mui-selected {
      color: $--grey-900;
      background-color: $--color-primary-dark;
    }
  }
}
