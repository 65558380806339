@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.wave {
  position: absolute !important;
  transform: scale(10);
  @media screen and (max-width: 767px) {
    transform: scale(5) rotate(60deg) !important;
  }
}

.SnapScroll {
  section {
    min-height: 100vh;
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
  .float {
    section {
      &:nth-of-type(1) {
        // background-color: $--color-primary;
        background-color: $--background-navy;
      }
      &:nth-of-type(2) {
        // background-color: $--color-secondary;
        background-color: $--background-navy-secondary;
      }
      &:nth-of-type(3) {
        // background-color: $--color-tertiary;
        background-color: $--background-navy-light;
      }
      &:nth-of-type(4) {
        background-color: $--color-quaternary;
      }
    }
  }
}

.Hero {
  position: relative;
  height: 100vh;
  overflow: hidden;

  > :global(.container) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    // padding-top: pxem(30);
  }

  .decoration {
    position: fixed;
    z-index: 1;
    transform-origin: center;
    // &:first-of-type {
    width: percentage(422 / 1440);
    top: percentage(120 / 800);
    left: percentage(34 / 1440);

    @media screen and (max-width: 767px) {
      width: pxrem(365);
      left: pxrem(-120);
    }
    // }
    &:last-of-type {
      width: percentage(308 / 1440);
      top: auto;
      left: auto;
      bottom: percentage(132 / 800);
      right: percentage(60 / 1440);

      @media screen and (max-width: 767px) {
        width: pxrem(340);
        top: auto;
        bottom: 10%;
        right: pxrem(-200);
      }
    }
  }

  // .box {
  //   position: relative;
  //   width: fit-content;

  //   h1 {
  //     position: fixed;
  //     inset: 0;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     // position: relative;
  //     margin: 0;
  //     color: $--text-white;
  //     line-height: 1;
  //     text-align: center;
  //     text-shadow: 0px 4px 4px rgba($--color-black, 0.25);
  //     text-transform: uppercase;
  //     z-index: 2;
  //   }
  // }

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > *:not(.decoration) {
      flex: 1;
      position: relative;
      z-index: 2;
      will-change: transform;
    }

    .wave {
      z-index: 1;
    }

    h1 {
      color: $--grey-100;
      font-size: pxem(40);
    }

    p {
      font-size: pxem(18);
      color: $--grey-100;

      & + p {
        margin-top: 10px;
      }
    }

    @media screen and (max-width: 991px) {
      flex-direction: column;

      h1,
      p {
        text-align: center;
        text-shadow: 0px 2px 4px rgba($--color-black, 0.65);
      }

      .image {
        // display: none;
        width: 60%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) !important;
        z-index: 1;
      }
    }
    @media screen and (max-width: 575px) {
      .image {
        width: 90%;
      }
    }
  }
}

.Product {
  position: relative;
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: $--background-navy-secondary;

  > :global(.container) {
    display: flex;
    flex-direction: column;
  }

  .product {
    position: relative;
    display: flex;
    justify-content: center;
    padding: pxrem(0 24);
    margin: auto;
    z-index: 3;
    width: 100%;
    max-width: 500px;

    img {
      position: relative;
      z-index: 1;
      will-change: transform;
      @media screen and (min-width: 768px) {
        width: 80%;
      }
    }
  }

  .button {
    position: absolute;
    white-space: nowrap;
    bottom: percentage(250 / 800);
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
  }

  .wave {
    top: 50%;
    @media screen and (max-width: 767px) {
      transform: scale(5) rotate(120deg) !important;
    }
  }

  & + .Product {
    .wave {
      transform: scale(10) rotate(-10deg) !important;
      @media screen and (max-width: 767px) {
        transform: scale(5) rotate(240deg) !important;
      }
    }
  }
}

.Features {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-top: pxem(35);
  padding-bottom: pxem(100);
  // background-color: $--grey-100;
  text-align: center;

  .grid {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, 1fr);
    column-gap: pxem(20);
    row-gap: pxem(40);
    margin-top: pxem(40);

    .rowSpan {
      grid-row: span 3;
      max-width: 400px;
    }

    .demo {
      display: flex;
      align-self: center;
      width: 100%;
      pointer-events: none;
      // width: auto;
      // max-width: pxrem(328);
    }

    .feature {
      font-size: 14px;
      :global(.h6) {
        // @include font-roboto();
        margin-bottom: pxem(22, 24);
      }

      @media screen and (min-width: 1440px) {
        width: percentage(215 / 375);
        margin: auto;
      }
    }

    @media screen and (max-width: 1199px) {
      grid-auto-flow: row;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;

      .demo {
        margin: auto;
      }

      .rowSpan {
        grid-row: auto;
        grid-column: span 2;
      }

      .feature {
        order: 1;
      }
    }

    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;

      .demo {
        grid-column: auto;
        width: 100%;
        height: auto;
      }

      .rowSpan {
        grid-row: auto;
        grid-column: auto;
      }
    }
  }

  .wave {
    top: 40%;
  }
}

.Cards {
  padding-top: percentage(100 / 1440);
  padding-bottom: percentage(100 / 1440);

  > :global(.container) {
    display: flex;
    flex-direction: column;
  }

  .item {
    &:not(:first-child) {
      margin-top: percentage(100 / 1440);
    }
  }
}

.ProductInfo {
  padding-top: pxem(45);
  padding-bottom: pxem(70);
  background-image: url(../../assets/img/product-background.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  > :global(.container) {
    display: flex;
    flex-direction: column;
  }

  h3 {
    text-align: center;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: pxem(70);
    margin-top: pxem(50);

    @media screen and (max-width: 1439px) {
      gap: pxrem(30);
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: pxrem(50);
    }

    .card {
      display: flex;
      flex-direction: column;
      width: percentage(260 / 317);
      margin: auto;

      @media screen and (max-width: 1439px) {
        width: 100%;
      }
      @media screen and (max-width: 767px) {
        max-width: pxrem(450);
      }

      .placeholder {
        margin-bottom: pxem(20);
      }

      :global(.h6) {
        margin-top: pxem(20, 24);
        margin-bottom: pxem(20, 24);
        text-align: center;
      }

      ul {
        list-style-type: disc;

        > li {
          font-size: pxrem(10);
          padding-bottom: 10px;
          > span {
            font-size: pxrem(14);
          }
        }
      }

      .button {
        margin: auto;
        margin-top: pxem(20);
      }
    }
  }
}

.Contact {
  position: relative;
  overflow: hidden;
  padding-top: pxem(70);
  padding-bottom: pxem(100);
  color: $--text-dark;

  :global(.h4) {
    color: $--text-dark;
  }

  > :global(.container) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: pxem(20);

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: pxrem(40);
    }
  }

  .info {
    @include font-poppins();
    @media screen and (max-width: 767px) {
      text-align: center;
    }
    .item {
      .label {
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-weight: 700;
        line-height: pxem(36);
      }
      .value {
        display: flex;
        flex-direction: column;
        font-size: 14px;
      }

      &:not(:first-child) {
        margin-top: pxem(20);
      }
    }
  }

  .message {
    .form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: pxem(26);

      @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
      }

      .fullwidth {
        grid-column: span 2;

        @media screen and (max-width: 767px) {
          grid-column: auto;
        }
      }

      .input {
        // @include font-roboto();
        flex: 1;
        border: none;
        background: none;
        font-size: pxem(16);
        padding: pxem(16 8);
        border: 1px solid $--grey-300;
        color: $--text-dark;

        &::placeholder {
          color: $--grey-500;
        }

        &.textarea {
          resize: none;
          min-height: pxem(100);
        }
      }

      .submit {
        margin-left: auto;
      }
    }
  }
}

.PhoneDemo {
  position: relative;
  box-sizing: border-box;
  margin: auto;
  width: clamp(280px, calc(500 / 955 * (100vh - 40px)), 328px);
  // height: clamp(618px, calc(100vh - 40px), 724px);
  border: 10px solid #292929;
  border-radius: 50px;
  overflow: hidden;

  @media screen and (max-width: 575px) {
    width: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: percentage(216 / 450);
    height: 0;
    padding-top: percentage(30 / 450);
    background-color: #292929;
    border-radius: 0 0 40px 40px;
    z-index: 99;
  }
}
