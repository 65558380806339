@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.LogoLoading {
  display: flex;
  justify-content: center;
  align-items: center;

  &.navy {
    background-color: $--background-navy;
  }

  .logo {
    max-width: 250px;
    opacity: 0.9;
    animation: heartbeat 1.8s infinite;

    @media screen and (max-width: 575px) {
      max-width: 150px;
    }
  }
}

@keyframes heartbeat {
  0% {
    opacity: 0.85;
    transform: scale(0.9);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.85;
    transform: scale(0.9);
  }
  75% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.85;
    transform: scale(0.9);
  }
}

// .WaveLoading {
//   @include font-poppins();
//   text-transform: uppercase;
//   font-weight: bold;
//   font-size: 100pt;
//   text-align: center;
//   height: 120px;
//   line-height: 110px;
//   vertical-align: bottom;
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 100px;
//   bottom: 0;
//   display: block;
//   // wave animation
//   background-image: url("https://i.imgur.com/uFpLbYt.png");
//   //   @include background-clip(text);
//   background-clip: text;
//   color: rgba(0, 0, 0, 0);
//   text-shadow: 0px 0px rgba(255, 255, 255, 0.06);
//   animation: wave-animation 1s infinite linear,
//     loading-animation 10s infinite linear alternate;
//   background-size: 200px 100px;
//   background-repeat: repeat-x;
//   opacity: 1;
// }

// @keyframes wave-animation {
//   0% {
//     background-position: 0 bottom;
//   }
//   100% {
//     background-position: 200px bottom;
//   }
// }
// @keyframes loading-animation {
//   0% {
//     background-size: 200px 0px;
//   }
//   100% {
//     background-size: 200px 200px;
//   }
// }
