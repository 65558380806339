@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.Checkout {
  position: relative;
  display: flex;
  //   display: grid;
  //   grid-template-columns: repeat(2, 1fr);
  height: 100vh;
  overflow: hidden;

  //   @media screen and (max-width: 991px) {
  //     grid-template-columns: 1fr;
  //   }
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $--text-dark;
  flex-grow: 1;
  :global(.ico-bag) {
    font-size: pxem(55);
    margin-top: pxem(20, 55);
  }
}

.flow {
  padding: pxem(36 70);
  padding-bottom: percentage(150 / 1440);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: pxem(30);

    h3 {
      margin: 0;
      text-transform: uppercase;
    }

    .logo {
      max-width: pxrem(114);
    }
  }

  .section {
    display: flex;
    flex-direction: column;

    & + .section {
      margin-top: pxem(25);
    }

    .title {
      margin-bottom: pxem(10);
    }

    .field {
      display: flex;
      gap: pxem(15);
      width: 100%;

      & + .field {
        margin-top: pxem(15);
      }

      > div {
        flex: 1;
      }
    }

    .radio {
      @include font-poppins();
      color: $--text-dark;
      font-size: pxem(14);
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr 0.4fr;
      gap: pxem(15, 14);

      .date {
        color: $--color-success;
        font-size: pxem(12, 14);
      }

      .price {
        text-align: right;
        font-weight: 500;
        span {
          font-size: pxem(11, 14);
          margin-right: pxrem(4);
          font-weight: 300;
        }
      }
    }

    :global(.MuiInput) {
      color: $--grey-500;
      font-size: pxem(14);
    }

    :global(.MuiSelect) {
      color: $--grey-500;
    }

    .block {
      & + .block {
        margin-top: pxem(30);
      }
    }

    .card {
      position: relative;
      display: flex;
      padding: pxem(20 15);
      border: 1px solid $--grey-300;
      border-radius: pxem(20);

      @media screen and (max-width: 575px) {
        padding-top: pxrem(12);
        padding-bottom: pxrem(12);
      }

      .content {
        flex-grow: 1;
        font-size: pxem(14);
        color: $--text-dark;
      }

      .strong {
        font-size: pxrem(16);
        color: $--color-black;
        font-weight: 700;
      }
    }

    .thankyou {
      font-size: pxem(28);
      font-weight: 500;
      text-transform: uppercase;
    }

    .message {
      font-weight: 500;
      margin-bottom: pxem(30);
    }
  }

  .foot {
    margin-top: pxem(25);

    .submit {
      margin-top: pxem(30);
      margin-bottom: pxem(15);
    }

    .alink,
    .print {
      transition: color $--hover;
      text-decoration: underline;

      &:hover {
        color: darken($--color-primary-dark, 25%);
      }
    }

    .print {
      @include font-poppins();
      color: $--text-dark;
      font-weight: 300;
    }
  }
}

.preview {
  padding: percentage(90 / 1440) percentage(120 / 1440);
  background-color: $--grey-100;
  border-left: 1px solid $--grey-300;

  @media screen and (max-width: 991px) {
    position: fixed;
    z-index: 1;
    right: 0;
    border: none;
    box-shadow: -6px 0px 6px 0px rgba($--text-dark, 0.25);
    height: 100%;
    box-sizing: border-box;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
  }

  .toggle {
    display: none;
    position: absolute;
    top: 80%;
    right: 100%;
    justify-content: center;
    align-items: center;
    font-size: pxem(24);
    padding-top: pxrem(30);
    padding-bottom: pxrem(30);
    width: pxrem(60);
    height: pxrem(60);
    background-color: inherit;
    border-radius: pxrem(50) 0 0 pxrem(50);
    box-shadow: -6px 0px 6px 0px rgba($--text-dark, 0.25);

    --decor-radius: 15px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: var(--decor-radius);
      height: var(--decor-radius);
      right: 0;
      z-index: 1;
      background-color: inherit;
      box-shadow: -8px 0px 8px 0px rgba($--text-dark, 0.25);
      mask-position: top left, top right, bottom left, bottom right;
      mask-repeat: no-repeat;
      mask-size: 100%;
    }

    &:before {
      top: 100%;
      mask-image: radial-gradient(
        circle at bottom left,
        transparent 0,
        transparent var(--decor-radius),
        black var(--decor-radius)
      );
    }

    &:after {
      bottom: 100%;
      mask-image: radial-gradient(
        circle at top left,
        transparent 0,
        transparent var(--decor-radius),
        black var(--decor-radius)
      );
    }

    .button {
      &:before {
        content: "";
        position: absolute;
        inset: 0;
      }
    }

    @media screen and (max-width: 991px) {
      display: flex;
    }
  }

  .close {
    display: none;
    justify-content: flex-end;
    font-size: pxrem(20);
    color: $--text-dark;

    .button {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        inset: -5px;
      }
    }

    @media screen and (max-width: 575px) {
      display: flex;
    }
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $--text-dark;
    .text {
      font-size: pxem(24);
    }
    .button {
      @include font-poppins();
      color: $--text-dark;
    }
  }

  .summary {
    .row {
      display: flex;
      justify-content: space-between;
      & + .row {
        margin-top: pxem(10);
      }
      .right {
        font-weight: 500;
      }
      &.total {
        font-size: pxem(28);
        font-weight: 500;
        span {
          font-size: pxem(14, 28);
          font-weight: 400;
        }
      }
    }
  }

  .border {
    height: 1px;
    background-color: $--grey-500;
    margin: pxem(25) 0;
    @media screen and (max-width: 991px) {
      margin: pxrem(12) 0;
    }
  }
}

.cartlist {
  padding: pxrem(10 0);
  // max-height: 300px;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.cartitem {
  display: flex;
  // grid-template-columns: repeat(3, auto);
  gap: pxem(20);

  .content & {
    font-size: pxrem(16);
  }

  & + .cartitem {
    margin-top: pxem(25);
  }

  .left {
    position: relative;
    flex-shrink: 0;

    .thumbnail {
      min-width: 75px;
    }

    .badge {
      position: absolute;
      top: -9px;
      right: -9px;
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $--grey-600;
      border-radius: 50%;
      font-size: 10px;
      color: $--color-white;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    font-size: pxem(14);
    .number {
      color: $--grey-600;
      font-size: pxem(12, 14);
    }
    .texture {
      color: $--grey-600;
      font-size: pxem(12, 14);
      margin-bottom: pxem(10, 14);
    }
    .quantityNum {
      color: $--color-black;
      font-size: pxrem(16);
      font-weight: 400;
    }
  }

  .price {
    font-size: pxem(14);
    font-weight: 500;
    // text-align: right;
    margin-left: auto;
  }

  .quantity {
    max-width: pxrem(60);
    margin-top: auto;
    justify-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $--color-primary;
    border-radius: pxrem(50);
    overflow: hidden;

    :global(.MuiButton) {
      padding: pxem(4);
      border-radius: 0;
      :global(.MuiSvgIcon-root) {
        font-size: pxem(14);
      }
    }
    .number {
      width: pxem(28);
      font-size: pxem(12);
      text-align: center;
    }
  }
}

.flow,
.preview {
  @include font-poppins();
  display: flex;
  flex-direction: column;
  flex: 1;
  @media screen and (max-width: 1199px) {
    padding-left: pxrem(40);
    padding-right: pxrem(40);
  }

  &.single {
    flex: 0 0 50%;
    @media screen and (max-width: 991px) {
      flex: auto;
    }
  }
}

.mask {
  position: fixed;
  inset: 0;
  background-color: rgba($--text-dark, 0.25);
}

@media print {
  .flow {
    &.single {
      flex: auto !important;
    }
  }
  .thankyou,
  .message,
  .print,
  .submit {
    display: none !important;
  }
}
