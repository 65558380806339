@import "assets/styles/base/variables";
@import "assets/styles/base/utils";

.Product {
  padding-top: pxem(72);
  padding-bottom: pxem(64);

  > :global(.container) {
    display: flex;
    flex-direction: column;
  }
  h4 {
    @media screen and (min-width: 1200px) {
      font-size: pxrem(40);
    }
    margin: 0;
    margin-bottom: pxem(40, 50);
    text-align: center;
    text-transform: uppercase;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: pxem(16);
    min-height: 400px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
  .item {
    @include font-poppins();
    padding: pxem(28 56);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 3px solid rgba($--color-primary-dark, 0);
    border-radius: pxem(30);

    @media screen and (min-width: 991px) {
      transition: $--hover;
      transition-property: border-color, box-shadow;
      &:hover {
        border-color: $--color-primary-dark;
        box-shadow: 0 0 pxem(14) rgba($--color-black, 0.08);
      }
    }

    @media screen and (max-width: 1439px) {
      padding-left: pxrem(24);
      padding-right: pxrem(24);
    }
    @media screen and (max-width: 991px) {
      padding: pxrem(12 0);
    }
    @media screen and (max-width: 767px) {
      max-width: pxrem(450);
      width: 100%;
      margin: auto;
    }
  }
  .image {
    margin: pxem(20 0);
  }
  .price {
    font-size: pxem(18);
    font-weight: 500;
  }
  .remark {
    font-size: pxem(12);
  }
  .label {
    font-size: pxem(20);
    margin-bottom: pxem(12, 20);
    font-weight: 500;
    // @media screen and (max-width: 1199px) {
    //   font-size: pxrem(20);
    // }
    @media screen and (max-width: 991px) {
      font-size: pxrem(18);
    }
  }
  .subline {
    font-size: pxem(14);
    margin-bottom: pxem(20, 14);
  }
  .desc {
    font-size: pxem(14);
    margin-bottom: pxem(12, 14);
    font-weight: 400;
    font-style: italic;
  }
}

.Step {
  text-align: center;
  padding-top: pxem(72);
  padding-bottom: pxem(64);
  // background-color: $--color-tertiary;
  background-color: $--background-navy-light;
  counter-reset: step;

  > :global(.container) {
    display: flex;
    flex-direction: column;
  }
  h3 {
    color: $--color-white;
    text-transform: uppercase;
    margin: 0;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: pxem(110);
    margin-top: percentage(80 / 1440);
    margin-bottom: percentage(80 / 1440);

    @media screen and (max-width: 1199px) {
      gap: pxrem(50);
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
  .step {
    @include font-poppins();
    @media screen and (max-width: 767px) {
      max-width: pxrem(450);
      margin: auto;
    }
    .label {
      position: relative;
      // @include font-roboto();
      color: $--color-white;
      font-size: pxrem(28);
      font-size: 500;

      @media screen and (max-width: 991px) {
        font-size: pxrem(24);
      }
      // @media screen and (max-width: 575px) {
      //   font-size: pxrem(32);
      // }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @include font-poppins();
        color: $--text-dark;
        font-size: pxem(24, 36);
        font-weight: 700;
        counter-increment: step;
        content: counter(step);
        width: pxem(42, 24);
        height: pxem(42, 24);
        background-color: $--color-primary-dark;
        border-radius: 50%;
      }
    }
    .image {
      width: pxrem(177);
      margin: auto;
      margin-bottom: pxem(36);
    }
    .desc {
      color: $--color-white;
      margin: auto;
      margin-top: pxem(35);
      width: percentage(370 / 460);

      @media screen and (max-width: 1199px) {
        width: 100%;
      }
    }
  }
  .button {
    margin: auto;
  }
}

.Business {
  padding-top: percentage(120 / 1440);
  padding-bottom: percentage(120 / 1440);
  // background-color: $--color-primary;
  background-color: $--background-navy-secondary;

  > :global(.container) {
    display: grid;
    grid-template-columns: 5fr 7fr;
    gap: pxem(20);

    @media screen and (max-width: 1199px) {
      gap: pxrem(50);
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: pxrem(20);
    }
  }

  .image {
    width: percentage(418 / 530);
    margin: auto;

    @media screen and (max-width: 1199px) {
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      max-width: pxrem(450);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    color: $--grey-100;
    @media screen and (max-width: 1199px) {
      width: 100%;
    }

    h4 {
      color: $--grey-100;
      margin-bottom: 0.7em;
      @media screen and (max-width: 767px) {
        text-align: center;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
    }

    ul {
      font-size: pxem(18);
      // padding-left: pxem(20, 18);
      margin-bottom: pxem(20, 18);
      > li {
        @include font-poppins();
        line-height: 2.45;
      }

      @media screen and (max-width: 1199px) {
        font-size: pxrem(16);
      }

      @media screen and (max-width: 767px) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .button {
      margin-top: auto;
      @media screen and (max-width: 767px) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.Partner {
  padding-top: pxem(60);
  padding-bottom: pxem(60);
  // background-color: $--grey-100;

  .slogan {
    // @include font-roboto();
    font-size: pxem(24);
    font-weight: 700;
    text-align: center;
  }
  .marquee {
    margin-top: pxem(40);

    .image {
      width: pxem(120);
    }
    :global(.marquee) {
      gap: pxem(52);
      & + :global(.marquee) {
        margin-left: pxem(52);
      }
    }
  }
}

.Custom {
  margin-top: percentage(50 / 1440);
  margin-bottom: percentage(140 / 1440);

  > :global(.container) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: pxem(20);

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }

  .title {
    @include font-poppins();
    text-align: center;
    text-transform: uppercase;
    color: $--text-title;
    margin: pxem(20, 36) 0;
  }

  .steps {
    display: flex;
    gap: 10px 20px;
    margin: pxem(20) 0;

    @media screen and (max-width: 575px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    > div {
      flex: 1;

      @media screen and (max-width: 575px) {
        flex: 0 0 28%;
      }

      .image {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;

        @media screen and (max-width: 575px) {
          width: 40%;
        }
      }

      .desc {
        font-size: 10px;
        text-align: center;
      }
    }
  }

  .customise {
    @media screen and (max-width: 991px) {
      order: 2;
      text-align: center;
    }

    @include font-poppins();
    .row {
      // min-height: 30px;
      margin: pxem(14 0);
    }
    .price {
      font-size: pxem(20);
    }
    .bold {
      font-weight: 700;
    }
  }

  .product {
    // position: sticky;
    // top: 30px;
    margin-top: pxem(60);
    grid-row: span 3;
    display: grid;
    grid-template-columns: 1fr minmax(280px, 520px) 1fr;
    gap: pxem(20);
    height: fit-content;

    @media screen and (max-width: 991px) {
      // position: relative;
      // top: auto;
      margin-top: 0;
      column-gap: 0;
      order: 1;
    }

    > * {
      grid-column: 2;
    }
    text-align: center;
    .upload,
    .preview {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      z-index: 1;
    }
    // .upload {
    //   margin-top: 40px;
    // }
    .image {
      max-width: pxrem(471);
      width: 100%;
      z-index: 1;
      &.card {
        background-color: $--color-white;
        max-width: pxrem(550);
        // box-shadow: 0 pxem(10) pxem(16) rgba($--color-black, 0.25);
        border-radius: pxem(20);
        overflow: hidden;
      }
    }
    .cancel {
      position: absolute;
      color: $--grey-600;
      background-color: $--color-white;
      font-size: pxem(32);
      width: pxem(32, 32);
      height: pxem(32, 32);
      bottom: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
      transition: color $--hover;

      &:hover {
        color: $--grey-600;
      }
    }
    .flip {
      display: flex;
      font-size: pxem(33);
      margin-left: auto;
      margin-right: auto;
      margin-top: pxem(30, 33);
    }
    .info {
      margin-top: pxem(20);
      .color {
        @include font-poppins();
        font-size: pxem(18);
        color: $--background-primary;
      }
    }
  }

  .quantity {
    @include font-poppins();
    margin: pxem(24 0);
    p {
      font-size: 14px;
      color: $--text-title;
      margin: pxem(10 0);
    }
    :global(.MuiSelect-select) {
      padding-left: pxem(25, 18);
      padding-right: pxem(35, 18);
    }

    .quantityInput {
      display: flex;
      border: 1px solid $--grey-300;
      width: fit-content;
      @media screen and (max-width: 991px) {
        margin: auto;
      }

      :global(.MuiOutlinedInput-notchedOutline) {
        border: none;
      }
      :global(.MuiSvgIcon-root) {
        font-size: 14px;
      }
      :global(.MuiInputBase-input) {
        max-width: 60px;
        font-size: 14px;
        text-align: center;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }

  .field {
    display: flex;
    flex-direction: column;

    & + .field {
      margin-top: pxem(36);
      padding-top: pxem(36);
      border-top: 1px solid $--grey-300;
    }

    .label {
      @include font-poppins();
      color: $--text-title;
      font-weight: 700;
      margin-bottom: pxem(25);
    }
    .row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
      gap: pxem(25);

      & + .row {
        margin-top: pxem(25);
      }

      .spanTwo {
        grid-column: span 2;

        @media screen and (max-width: 767px) {
          grid-column: auto;
        }
      }
    }
  }

  .accordion {
    .priceTag {
      font-size: pxem(18);
      font-weight: 300;
      .price {
        font-size: pxem(36, 18);
        font-weight: 500;
      }
    }
    .ul {
      font-size: pxem(14);

      .li {
        display: flex;
        align-items: center;
        line-height: (40 / 14);

        :global(.MuiSvgIcon-root) {
          margin-right: 4px;
        }
      }
    }
  }

  .submit {
    margin-top: pxem(30);
    width: 100%;
    .button {
      max-width: pxrem(550);
      width: inherit;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
